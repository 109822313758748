// original layout changes

.bg-smoodi {
  background-image: url("../../../../images/bg.webp"),
    linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  background-blend-mode: overlay;
}
.bg-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  background-blend-mode: overlay;
}
// footer
.app-footer {
  bottom: 0;
  left: 0;
  position: static;
  right: 0;
  z-index: 0;
}
.app-main-content {
  min-height: calc(100vh - 10rem) !important;
}

.scrolltop {
  bottom: 70px !important;
}

//svg
.svg-icon.svg-icon-2 svg {
  height: 1.8rem !important;
  width: 1.8rem !important;
}

.hover-bg-light:hover {
  background-color: var(--kt-light) !important;
}

input:focus-visible {
  outline: 1px solid transparent;
}

.text-transform {
  text-transform: none !important;
}

/* Toggle switch */
.status-switch {
  position: relative;
  display: inline-block;
  width: 59px;
  height: 26px;
}
.status-switch input {
  display: none;
}
.status-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 6px;
  right: 0;
  bottom: 0;
  background-color: var(--kt-gray-400);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}
.status-slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 3px;
  bottom: 2px;
  background-color: var(--kt-white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
input:checked + .status-slider {
  background-color: var(--kt-primary);
}
input:checked + .status-slider:before {
  transform: translateX(25px);
}
.status-slider:after {
  content: "";
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 65%;
  font-size: 12px;
}
input:checked + .status-slider:after {
  content: "";
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 35%;
  font-size: 12px;
}