// smoodi-form layout changes

// form-control
.form-control {
  font-size: 1.2rem !important;
  &:focus {
    border-color: var(--kt-primary-active) !important;
    //box-shadow: var(--kt-input-focus-box-shadow);
  }
}
.form-control[readonly] {
  color: var(--kt-input-color) !important;
}
.form-control {
  text-transform: lowercase !important;
}

/* react-select dropdown */
.react-select {
  display: block;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--kt-form-check-label-color);
  background-clip: padding-box;
  appearance: none;
  border-radius: 0.475rem;
  background-color: var(--kt-input-bg) !important;
  min-height: 42px !important;
  border: 1px solid var(--kt-input-border-color);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.my-react-select__control {
  min-height: 42px !important;
  border: 1px solid var(--kt-input-border-color);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.my-react-select__control--is-focused {
  border: 1px solid var(--kt-primary-active) !important;
  border-color: var(--kt-primary-active) !important;
}
.css-t3ipsp-control {
  background-color: var(--kt-input-bg) !important;
  border-color: var(--kt-primary-active) !important;
  border: 1px solid var(--kt-primary-active) !important;
  min-height: 42px !important;
  box-shadow: none !important;
}
.css-1xc3v61-indicatorContainer {
  color: var(--kt-form-check-label-color);
  margin-right: 5px !important;
  padding: 0 !important;
}
.my-react-select__indicator {
  color: var(--kt-form-check-label-color);
  margin-right: 5px !important;
  padding: 0 !important;
}
.css-13cymwt-control {
  background-color: var(--kt-input-bg) !important;
  border: none !important;
  min-height: 43px !important;
}
.css-1u9des2-indicatorSeparator {
  display: none;
}
.css-15lsz6c-indicatorContainer {
  color: var(--kt-form-check-label-color) !important;
}
.css-1dimb5e-singleValue {
  color: var(--kt-input-color) !important;
}
.my-react-select__option {
  background-color: var(--kt-card-bg) !important;
}
.my-react-select__option--is-selected {
  background-color: var(--kt-form-check-input-bg-solid) !important;
  color: var(--kt-dark) !important;
}
.my-react-select__option--is-focused {
  background-color: var(--kt-primary-light) !important;
  color: var(--kt-dark) !important;
}
.css-qbdosj-Input {
  color: var(--kt-form-check-label-color) !important;
}
.css-1cfo1cf {
  color: var(--kt-form-check-label-color) !important;
}
.css-166bipr-Input {
  color: var(--kt-form-check-label-color) !important;
}
.css-1jqq78o-placeholder {
  color: var(--kt-input-color) !important;
}
.css-1p3m7a8-multiValue {
  background-color: var(--kt-primary-light) !important;
}
.css-wsp0cs-MultiValueGeneric {
  color: var(--kt-input-color) !important;
}
.css-12a83d4-MultiValueRemove:hover {
  background-color: var(--kt-form-check-input-bg-solid) !important;
  color: var(--kt-dark) !important;
}
.was-validated .react-select:invalid,
.react-select.is-invalid {
  border-color: var(--kt-danger);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3875rem) center;
  background-size: calc(0.75em + 0.775rem) calc(0.75em + 0.775rem);
}
.was-validated .my-react-select__control:invalid:focus,
.my-react-select__control.is-invalid:focus {
  border-color: var(--kt-primary-active) !important;
  box-shadow: none !important;
}

// date picker
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: var(--kt-primary) !important;
}
.rdtPicker td.rdtToday:before {
  border-bottom: 7px solid var(--kt-primary) !important;
}
.rdtPicker {
  background-color: var(--kt-body-bg) !important;
}
.rdtPicker {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
}
.rdtPicker .rdtTimeToggle:hover,
.rdtSwitch:hover,
.rdtNext:hover,
.rdtPrev:hover,
.rdtPicker td.rdtDay:hover,
.rdtCounter .rdtBtn:hover,
td.rdtMonth:hover,
td.rdtYear:hover {
  background-color: var(--kt-gray-300) !important;
}

// Date picker invalid
.date-control.is-invalid {
  .form-control {
    border-color: var(--kt-danger);
  }
}
.date-control.is-invalid:focus {
  .form-control {
    border-color: var(--kt-primary-active) !important;
    box-shadow: none !important;
  }
}

.file-link {
  cursor: pointer;
}
